@use "@angular/material" as mat;

$svnl-palette: (
  50:#e3f2fb,
  100:#badef7,
  200:#90caf2,
  300:#65b5ec,
  400:#43a5e9,
  500:#1f97e6,
  600:#1689d9,
  700:#0a77c7,
  800:#0067b5,
  900:#004996,
  contrast: (
    50:rgba(black, 0.87),
    100:rgba(black, 0.87),
    200:rgba(black, 0.87),
    300:#e3f2fb,
    400:#e3f2fb,
    500:#e3f2fb,
    600:#e3f2fb,
    700:#e3f2fb,
    800:#e3f2fb,
    900:#e3f2fb,
  )
);

$svnl-warn-palette: (
  50:#fff2df,
  100:#ffdeb0,
  200:#ffc97e,
  300:#ffb24a,
  400:#ffa122,
  500:#ff9100,
  600:#fc8500,
  700:#f67500,
  800:#f06400,
  900:#e74702,
  contrast: (
    50:rgba(black, 0.87),
    100:rgba(black, 0.87),
    200:rgba(black, 0.87),
    300:#e3f2fb,
    400:#e3f2fb,
    500:#e3f2fb,
    600:#e3f2fb,
    700:#e3f2fb,
    800:#e3f2fb,
    900:#e3f2fb,
  )
);

$svnl-okay-palette: (
  50:#f1f8e5,
  100:#dcecbf,
  200:#c4df96,
  300:#abd26a,
  400:#98c946,
  500:#85c019,
  600:#75b00f,
  700:#5f9c00,
  800:#4a8900,
  900:#1d6800,
  contrast: (
    50:rgba(black, 0.87),
    100:rgba(black, 0.87),
    200:rgba(black, 0.87),
    300:#e3f2fb,
    400:#e3f2fb,
    500:#e3f2fb,
    600:#e3f2fb,
    700:#e3f2fb,
    800:#e3f2fb,
    900:#e3f2fb,
  )
);

$svnl-error-palette: (
  50:#fee8e7,
  100:#ffcaba,
  200:#ffa78e,
  300:#ff8260,
  400:#ff633c,
  500:#ff4118,
  600:#ff3a13,
  700:#f9320d,
  800:#eb2806,
  900:#d30f00,
  contrast: (
    50:rgba(black, 0.87),
    100:rgba(black, 0.87),
    200:rgba(black, 0.87),
    300:#e3f2fb,
    400:#e3f2fb,
    500:#e3f2fb,
    600:#e3f2fb,
    700:#e3f2fb,
    800:#e3f2fb,
    900:#e3f2fb,
  )
);

$svnl-primary: mat.define-palette($svnl-palette, 800);
$svnl-accent: mat.define-palette($svnl-palette, 100);
$svnl-warn: mat.define-palette($svnl-warn-palette, 800);
$svnl-okay: mat.define-palette($svnl-okay-palette, 600);
$svnl-error: mat.define-palette($svnl-error-palette, 800);

$svnl-primary-color: mat.get-color-from-palette($svnl-primary);
$svnl-accent-color: mat.get-color-from-palette($svnl-accent);
$svnl-warn-color: mat.get-color-from-palette($svnl-warn);
$svnl-okay-color: mat.get-color-from-palette($svnl-okay);
$svnl-error-color: mat.get-color-from-palette($svnl-error);
$svnl-error-background-color: mat.get-color-from-palette($svnl-error-palette, 100);
$svnl-warn-background-color: mat.get-color-from-palette($svnl-warn-palette, 100);
$svnl-info-background-color: mat.get-color-from-palette($svnl-palette, 100);
$svnl-okay-background-color: mat.get-color-from-palette($svnl-okay-palette, 100);

$svnl-header-row-background: #EBEBF0;
$svnl-header-row-color: #002D65;
$svnl-mat-card-section-background: #fcfcfc;
$svnl-mat-card-border-color: #e6ebf0;
$svnl-text-color: #4d6c94;

$svnl-toggle-background-checked: mat.get-color-from-palette($svnl-palette, 900);

$svnl-toggle-warn-background-checked: mat.get-color-from-palette($svnl-warn-palette, 900);
