/* You can add global styles to this file, and also import other style files */
@use './theme/typo' as *;
@use './theme/theme' as *;
@use './theme/tables' as *;
@use './theme/palette.scss' as *;
@import "../node_modules/leaflet/dist/leaflet.css";

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  background: url('/assets/images/bg.jpg') no-repeat;
  background-size: cover;
}

.primary {
  color: $svnl-primary-color;
}

.svnl-text {
  color: $svnl-text-color;
}

.svnl-header {
  color: $svnl-header-row-color;
}

div.svnl-warning{
  border: 1px solid $svnl-warn-color;
  border-radius: 3px;
  padding: 0.75rem;
  text-align: center;
  color: $svnl-warn-color;
  background-color: $svnl-warn-background-color;
}

.svnl-alert {
  border-radius: 3px;
  padding: 0.75rem;
  text-align: center;
  max-width: 50rem;
  margin-top: 2rem;

  &.svnl-error {
    border: 1px solid $svnl-error-color;
    color: $svnl-error-color;
    background-color: $svnl-error-background-color;
  }

  &.svnl-warning {
    border: 1px solid $svnl-warn-color;
    color: $svnl-warn-color;
    background-color: $svnl-warn-background-color;
  }

  &.svnl-info {
      border: 1px solid $svnl-primary-color;
      color: $svnl-primary-color;
      background-color: $svnl-info-background-color;
  }

  &.svnl-success {
    border: 1px solid $svnl-okay-color;
    color: $svnl-okay-color;
    background-color: $svnl-okay-background-color;
  }
}

.svnl-margin-left-2-rem{
  margin-left: 2rem;
}

.svnl-margin-bottom-2-rem{
  margin-bottom: 2rem;
}

.svnl-margin-right-2-rem{
  margin-right: 2rem;
}

// Align FA icons in center of input
.align-fa-icon-center {
  position: relative;
  top: -0.4rem;
}

.CandidateInfoComponent .mat-dialog-container{
  padding: 0;
}

@media (max-width: 600px) {

  .map-container {
    max-width: 100vw !important;
    height: 40rem !important;
    width: 100vw !important;
  }
}


