@use "@angular/material" as mat;
@use "palette" as *;

@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";

$svnl-theme: mat.define-light-theme(
  (
    color: (
      primary: $svnl-primary,
      accent: $svnl-accent,
      warn: $svnl-warn,
    ),
  )
);

@include mat.core();
@include mat.all-component-themes($svnl-theme);

.fa.invalid {
  color: darkgrey;
  cursor: not-allowed;
}

.fa.fa-check-circle.valid {
  color: green;
}

.fa.fa-check-circle.invalid {
  color: darkgrey;
}

.fa.fa-user-circle.invalid {
  color: darkgrey;
  cursor: not-allowed;
}

.fa.fa-check-circle.empty {
  color: darkgrey;
}
