@use "palette" as *;

.table-wrapper {
  margin-bottom: 1.5REM;
  border-radius: 0.375REM;
  background: #fff;
  overflow: hidden; /* Clips any scrollbars that appear */
}

.svnl-table {
  width: 100%;
  overflow: auto; /* Allow scrolling within the table */
  display: grid;
}

.svnl-table thead,
.svnl-table tbody,
.svnl-table tr {
  display: contents;
}

.svnl-table th {
  position: relative;
  font-size: .875rem;
  font-weight: 600;
  color: $svnl-header-row-color;
  background: $svnl-header-row-background;
}

.svnl-table th,
.svnl-table td {
  text-align: left;
  padding: 16px 20px;
}

.svnl-table tr td {
  border-top: 1px solid #ccc;
  color: $svnl-primary-color;
}

mat-table {
  width: 100%;
}

mat-row {
  width: 100%;
  min-height: 12px;

  mat-cell:first-of-type {
    padding-left: 0.875rem;
  }

  mat-cell {
    justify-content: center;
  }
}

.mat-header-row {
  display: flex;
  background: $svnl-header-row-background;

  mat-header-cell {
    justify-content: center;
    color: $svnl-header-row-color;
    font-size: 0.875rem;
    font-weight: 600;
  }
}
